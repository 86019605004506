import React from 'react'
import './Join.css'
import  { useEffect, useRef } from "react";
import emailjs from '@emailjs/browser'
const Join = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_dijgen6', 'template_ms8ozr6', form.current, 'hWktX3rIYtl-2hxMk')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <div className="join" id="joinus">
        <div className="left-j">
            <hr/>
            <div>
                <span>
                    ready to 
                </span>
                <span className='stroke-text level-up' >
                    level up ?
                </span>
            </div>
            <div>
                <span className='stroke-text'>your body</span><span className='joinwithus'>join with us</span>
            </div>
        </div>
        <div className="right-j">
            <form Ref={form}  className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user_email ' placeholder='enter your email to join' />
                <button className='btn btn-j'>Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join
