import React from 'react'
import './footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Linkedin from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
function footer() {
  return (
    <div className="footer-container">
        <hr/>
        <div className="footer">
            <div className="social-icons">
            <img src={Github} alt="" />
            <img src={Instagram} alt="" />
            <img src={Linkedin} alt="" />
            </div>
            <div className="logo">
               <img src={Logo} alt="" />
            </div>
        </div>
        <div className="blur blr-footer1"></div>
        <div className="blur blr-footer2"></div>
        
    </div>
  )
}

export default footer
